.App {
    background-color: #282c34;
    color: white;
}

.content {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(20px + 2vmin);
    color: white;
}

.topbar {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.topbar-items {
    padding: 1vh 5vw 1vh 0vw;
    text-align: center;
    font-size: calc(10px + 1vmin);
}

    .topbar-items:hover {
        color: #61dafb;
        cursor: pointer;
        -webkit-transform: translateY(0.5vw);
        transform: translateY(0.5vw);
    }

.back {
    position: fixed;
    margin-left: 90vw;
    margin-top: 80vh;
}

.intro {
    font-size: calc(10px + 1vmin);
    display: flex;
}
.head-line {
    font-size: calc(15px + 1vmin);
    width: 80vw;
    display: flex;
    margin: auto;
    margin-bottom: 2em;
    color: steelblue;
}
    .head-line::after {
        content: " ";
        height: 0.1vh;
        flex-grow: 1;
        background-color: steelblue;
        margin-top: 1em;
        margin-left: 1em;
    }

.about {
    font-size: calc(10px + 1vmin);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

.skills {
    margin: auto;
    margin-top: 1em;
    width: 80vw;
}
    .skill-title{
        font-size: 1.1em;
    }
    .skill-bar {
        display: flex;
        flex-direction: row;
    }
        .skill-bar p {
            font-size: 0.9em;
            width: 35%;
            margin-top: 0.8em;
        }

        .skill-bar span {
            margin-top: 0.8em;
            font-size: 0.8em;
            color: darkgray;
        }

.experience {
    font-size: calc(10px + 1vmin);
    min-height: 100vh;
    height: fit-content;
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding-left: 2vw;
    margin-bottom: 2em;
}

.experienceSidebar {
    width: 15vw;
    min-width: fit-content;
}

    .sidebarItem {
        padding: 2vh 2px;
        cursor: pointer;
    }
        .sidebarItem:hover {
            font-size: 1.1em;
        }

.experienceContent {
    width: 70vw;
}

.experience-sub-content {
    padding: 2vh 2px;
}
    .experiencetitle {
        font-size: calc(12px + 1vmin);
    }
        .experiencetitle a {
            color: #61dafb;
            text-decoration: none;
        }
    .experience-sub-content p {
        margin: 0px;
    }

    .experience-sub-content span {
        font-size: 0.8em;
        color: darkgray;
    }

    .experience-sub-content div {
        font-size: calc(12px + 1vmin);
    }

.education {
    padding: 2vh 2px;
}

    .education div {
        font-size: calc(12px + 1vmin);
    }

    .education p {
        font-size: 0.8em;
    }

    .education a {
        color: #61dafb;
        text-decoration: none;
    }

.project {
    font-size: calc(10px + 1vmin);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}
.contact {
    font-size: calc(10px + 1vmin);
    min-height: 45vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 2em;
}

    .contact a {
        color: #61dafb;
        text-decoration: none;
    }

footer {
    text-align: center;
    padding-bottom: 2vh;
}